<template>
  <div class="section relative max-w-5xl mx-auto  z-0 py-16 md:pt-32 md:pb-20">
    <header class="text-center mx-auto mb-12 lg:px-20 block">
      <h2 class="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
        What I do</h2>
      <p class="mt-4 text-lg tracking-tight text-slate-700">
        My custom copywriting services will save you time and effort while creating a competitive edge
      </p>
    </header>

    <div class="grid grid-cols-2 gap-5 text-center">
      <div class="col-span-1 p-10 hover:bg-gray-100" v-for="item in data" :key="item.title">
        <div v-html="item.icon" class="inline-block text-gray-900 mb-4 text-center"></div>
        <div class="mt-2 font-display text-xl text-slate-900">{{item.title}}</div>
        <div class="mt-4 text-sm text-slate-600">{{item.body}}</div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path></svg>',
          title: "Email Campaigns",
          body: "Conversion-focused emails to help you cut through the ‘noise’ of a crowded inbox.",
        },

        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-chat-square-dots" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path></svg>',
          title: "Website Copy",
          body: "Content that prompts action, builds credibility and is consistent with your brand.",
        },
        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-badge-ad" viewBox="0 0 16 16"><path d="M3.7 11l.47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852l.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z"></path><path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"></path></svg>',
          title: "Real Estate Listings",
          body: "Painting an ideal picture of the property, making it stand out against the competition.",
        },
      ],
    };
  },
};
</script>

<style>
</style>