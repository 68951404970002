<template>
  <div class="section max-w-5xl mx-auto  relative z-0 py-16 md:pt-32 md:pb-20">
    <!-- section header -->
    <header class="text-center mx-auto mb-12 lg:px-20">
      <h2 class="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
        Why you need a <strong>copywriter</strong></h2>

      <!-- <p class="text-gray-600 leading-relaxed font-light text-xl mx-auto pb-2">Have questions about service, please contact us.</p> -->
    </header><!-- end section header -->
    <div class="text-justify leading-loose flex flex-col space-y-2 px-5">

      <p>The right words - or 'copy' - can generate leads, amplify revenue, satisfy customers and build a stronger brand.</p>

      <p>From websites to brochures, emails to social media posts, signage to online advertising, you'll find copy almost anywhere you look.</p>

      <p>That's why powerful and persuasive copy is critical to business success.</p>

      <p>I write for small businesses, to help them increase their sales and improve their service.</p>

      <p>My custom copywriting services will save you time and effort while creating a competitive edge.</p>

      <!-- [Contact me today to learn more] -->

    </div>
  </div>

</template>

<script>
export default {};
</script>

<style>
</style>