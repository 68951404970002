<template>
  <div class="py-5">
    <div class="">
      <div class="max-w-5xl mx-auto ">
        <nav class="flex  justify-between items-center relative p-5 mt-5">
          <router-link to="/" class="flex-1">
            <img src="/img/logo.png" class=" mx-auto h-20" />
          </router-link>
          <button @click="show_menu = !show_menu" class="flex-1 justify-end flex md:hidden text-gray-700 p-2 rounded  mr-5" type="button" aria-controls="navbar-main" aria-expanded="false" aria-label="Toggle navigation">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
          <div class="flex-1 space-x-6 justify-end flex invisible md:visible">
            <router-link to="/" class="cursor-pointer hover:underline">Home</router-link>
            <router-link to="/" class="cursor-pointer hover:underline">Services</router-link>
            <router-link to="/" class="cursor-pointer hover:underline">Contact</router-link>
          </div>
        </nav>
        <div class="flex flex-col bg-white space-y-1 m-3 rounded-lg" v-show="show_menu">
          <router-link to="/" class="p-2">Home</router-link>
          <router-link to="/" class="p-2">Services</router-link>
          <router-link to="/" class="p-2">Contact</router-link>
        </div>
      </div>
      <div class=" bg-white rounded-md">
        <router-view />
      </div>
      <div class="py-5 px-5 flex justify-between mb-10">
        <div>footer lhs</div>
        <div>footer rhs</div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      show_menu: false,
    };
  },
  methods: {},
};
</script>

<style>
</style>