<template>
  <div class="section relative z-0 py-16 max-w-5xl mx-auto md:pt-32 md:pb-20">
    <div class="flex flex-wrap flex-row -mx-4 justify-center">
      <div class="max-w-ful px-4 w-full lg:w-8/12">
        <div class="w-full p-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeInUp;">
          <!-- section header -->
          <header class="text-center mx-auto mb-12 lg:px-20">
            <h2 class="text-2xl leading-normal mb-2 font-bold text-gray-800 font-mono">Contact</h2>

            <p class="text-gray-600 leading-relaxed font-light text-xl mx-auto pb-2">Have questions about service, please contact us.</p>
          </header><!-- end section header -->

          <!-- contact form -->
          <form action="#" class="text-gray-600">
            <div class="flex flex-wrap flex-row -mx-4">
              <div class="flex-shrink w-full max-w-full md:w-1/2 px-4 mb-6">
                <label class="inline-block mb-2" for="name">Your Name</label>
                <input type="text" name="name" class="w-full leading-5 relative py-3 px-5 rounded text-gray-800 bg-white border border-gray-200 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-400 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600" id="name">
                <div class="validate"></div>
              </div>
              <div class="flex-shrink w-full max-w-full md:w-1/2 px-4 mb-6">
                <label class="inline-block mb-2" for="email">Your Email</label>
                <input type="email" class="w-full leading-5 relative py-3 px-5 rounded text-gray-800 bg-white border border-gray-200 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-400 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600" name="email" id="email">
                <div class="validate"></div>
              </div>
            </div>
            <div class="mb-6">
              <label class="inline-block mb-2" for="subject">Subject</label>
              <input type="text" class="w-full leading-5 relative py-3 px-5 rounded text-gray-800 bg-white border border-gray-200 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-400 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600" name="subject" id="subject">
              <div class="validate"></div>
            </div>
            <div class="mb-6">
              <label class="inline-block mb-2" for="messages">Message</label>
              <textarea class="w-full leading-5 relative py-3 px-5 rounded text-gray-800 bg-white border border-gray-200 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-400 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600" name="message" rows="10" id="messages"></textarea>
              <div class="validate"></div>
            </div>
            <div class="text-center mb-6">
              <a class="py-2.5 px-10 inline-block rounded-lg text-center leading-normal text-white bg-primary-600 hover:text-white hover:ring-0 focus:outline-none focus:ring-0" href="#project">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block mr-1">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>

                Send message
              </a>
            </div>
          </form><!-- end contact form -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>