<template>
  <div class="section relative z-0 max-w-5xl mx-auto py-16 md:pt-32 md:pb-20">
    <!-- section header -->
    <header class="text-center mx-auto mb-12 ">
      <h2 class="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
        Expertise</h2>

      <!-- <p class="text-gray-600 leading-relaxed font-light text-xl mx-auto pb-2">Have questions about service, please contact us.</p> -->
    </header><!-- end section header -->
    <div class=" text-justify  flex flex-col space-y-6">

      <p>Working for over twenty years in retail, sales, marketing and management, I’ve developed a deep understanding of the reasons people buy products and services and - more importantly - the reasons they don’t.</p>

      <p>My copywriting is based on a reservoir of expert knowledge, so I can provide practical advice that’s relevant to you.</p>

      <p>I’ve written for companies such as Content House, Hays Personnel, Pioneer Mortgage Service, A.B. Paterson College, Threadwise and Tocuhpoint Global.</p>

      <p>Let me show you how my writing experience can help your business thrive.</p>
      <!-- [Contact me today to learn more] -->

    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>